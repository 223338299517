(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

var share = {
  winTop: screen.height / 2 - 520 / 2,
  winLeft: screen.width / 2 - 320 / 2,
  init: function init() {},
  facebook: function facebook(title, url) {
    this.open('http://www.facebook.com/sharer.php?s=100&p[title]=' + title + '&p[url]=' + url);
  },
  twitter: function twitter(title, url) {
    this.open('https://twitter.com/intent/tweet?text=' + title + '&url=' + url);
  },
  gplus: function gplus(title, url) {
    this.open('https://plus.google.com/share?url=' + url);
  },
  pinterest: function pinterest(title, url) {
    this.open('http://pinterest.com/pin/create/button/?url=' + url);
  },
  open: function open(url) {
    window.open(url, 'sharer', 'top=' + this.winTop + ',left=' + this.winLeft + ',toolbar=0,status=0,width=' + 520 + ',height=' + 320);
  }
};

$(document).ready(function () {
  var url = $("#curr-url").val(),
      title = $(".article-content > .header-article > h1.title").text();
  $(".btn-facebook").on('click', function (e) {
    e.preventDefault();
    share.facebook(title, url);
  });
  $(".btn-twitter").on('click', function (e) {
    e.preventDefault();
    share.twitter(title, url);
  });
  $(".btn-google").on('click', function (e) {
    e.preventDefault();
    share.gplus(title, url);
  });
  $(".btn-pinterest").on('click', function (e) {
    e.preventDefault();
    share.pinterest(title, url);
  });

  function StickyIt() {
    if ($(window).width() >= 976) {
      if (!$(".sticky-ad").parent().hasClass("sticky-wrapper")) {
        $(".sticky-ad").sticky({
          topSpacing: 50,
          bottomSpacing: $("#site-footer").outerHeight() + 20
        });
      }
      if (!$(".sticky-l").parent().hasClass("sticky-wrapper")) {
        $(".sticky-l").sticky({
          topSpacing: 50,
          bottomSpacing: $("#site-footer").outerHeight() + 20
        });
      }
    } else {
      $(".sticky-ad, .sticky-l").unstick();
    }
  }

  $(window).resize(function () {
    StickyIt();
  });
  StickyIt();

  $(".loadmore-btn").on('click', function () {
    var b = $(this);
    b.attr('disabled', 'disabled');
    var t = $(this).parent(),
        next = t.data("url");
    $.getJSON(next, function (data) {
      if (data.next_page_url) {
        t.data("url", data.next_page_url);
        b.removeAttr('disabled');
      } else {
        b.hide();
      }
      $.each(data.data, function (i, d) {
        t.find(" > ul").append('<li>' + '<figure>' + '<a href="news/' + d.permalink + '">' + '<img src="' + d.image_url + '">' + '</a>' + (d.is_featured == 1 ? '<div class="featured">' + '<i class="fa fa-rocket"></i>' + '</div>' : '') + '</figure>' + '<div class="main-content">' + '<header class="list-news-header">' + '<h1 class="title"><a href="' + d.permalink_url + '">' + d.title + '</a></h1>' + '<p> ' + d.time_ago + '  By <a href="/user/' + d.user.username + '">' + d.user.username + '</a></p>' + '</header>' + '<p class="summary">' + d.summary + '</p>' + '</div>' + '</li>');
      });
    });
  });

  $(".close-x-menu").on('click', function () {
    $(".extern-menu").removeClass("active");
  });
  $("#main-nav-toggle").on('click', function () {
    $(".extern-menu").addClass("active");
  });
});

},{}]},{},[1]);
